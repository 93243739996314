.customerInfoForm__background {
background-color: #42497c;
height: 100vh;
}

.customerInfoForm__formSection {
display: flex;
justify-content: center;
margin-top: 10px;
}

.customerInfoForm__form {
padding: 20px;
border-radius: 8px;
display: flex;
align-items: center;
flex-direction: column;
}

.customerInfoForm__form h1 {
font-size: 2.5rem;
margin-bottom: 15px;
text-align: center;
color: white;
}

.customerInfoForm__disclaimer {
background-color: #f9f9f9;
border: 1px solid #ddd;
padding: 10px;
margin-bottom: 15px;
border-radius: 4px;
width:400px ;
}

.customerInfoForm__disclaimer p {
margin: 0;
font-size: 14px;
color: #777;
}

.customerInfoForm__form p {
margin-bottom: 5px;
font-weight: 500;
}

.customerInfoForm__form input[type="date"],
.customerInfoForm__form input[type="time"],
.customerInfoForm__form input[type="text"],
.customerInfoForm__form input[type="email"],
.customerInfoForm__form textarea {
width: 400px;
padding: 10px;
font-size: 16px;
border: 1px solid #d1b9f0;
border-radius: 4px;
margin-bottom: 10px;
}

.customerInfoForm__form textarea {
resize: vertical;
min-height: 100px;
}

.customerInfoForm__form button {
background-color: #d1b9f0;
font-size: 1rem;
font-weight: 600;
color: #42497c;
border-radius: 5px;
padding: 8px 15px;
border: none;
cursor: pointer;
margin: 10px 0px;
}

.customerInfoForm__form button:hover {
opacity: 0.9;
}

.customerInfoForm__form span {
color: #d1b9f0;
cursor: pointer;
font-weight: 500;
text-decoration: underline;
}

@media only screen and (max-width:570px ){
    .customerInfoForm__form input[type="date"],
.customerInfoForm__form input[type="time"],
.customerInfoForm__form input[type="text"],
.customerInfoForm__form input[type="email"],
.customerInfoForm__form textarea,
.customerInfoForm__disclaimer {
        width: 300px;
    }
}

@media only screen and (max-width:370px ){
    .customerInfoForm__form input[type="date"],
.customerInfoForm__form input[type="time"],
.customerInfoForm__form input[type="text"],
.customerInfoForm__form input[type="email"],
.customerInfoForm__form textarea,
.customerInfoForm__disclaimer {
        width: 250px;
    }

}