.orderDisplay__background{
    background-color: #42497c;
    height: 100vh;
}

.orderDisplay__container{
    display: flex;
    justify-content: center;
}

.orderDisplay__container h1{
    font-size: 3rem;
}

.orderDisplay__container h2{
    font-size: 2rem;
}

.orderDisplay__leftSection{
    margin-right: 150px;
}

.orderDisplay__productLists{
    margin-bottom: 13px;
    display: flex;
    justify-content: space-between;
}

.orderDisplay__summary{
    display: flex;
    justify-content: space-between;
}

.orderDisplay__popupMessage{
    max-width: 900px;
    padding: 10px;
    border-radius:5px;
    font-weight: 300;
    margin: 10px 0px;
}

.orderDisplay__completedStatus{
    background-color: #78876a;
    border: 1px solid #676b4a;
}

.orderDisplay__uncompletedStatus{
    background-color: #9a001a;
    border: 2px solid #b31f2d;
}

.orderDisplay__summary p{
    margin-bottom: 10px;
}

@media screen and (max-width:1250px) {
    .orderDisplay__leftSection{
        margin-right: 30px;
    }
}

@media screen and (max-width: 1100px) {
    /* Change layout for smaller screens */
    .orderDisplay__container {
      flex-direction: column;
      align-items: center;
    }
  
    .orderDisplay__leftSection {
      margin-right: 0; /* Remove right margin */
      margin-bottom: 30px; /* Add bottom margin for spacing */
      max-width: 500px;
    }
    
    .orderDisplay__rightSection {
      width: 100%; /* Take full width */
      max-width: 300px;
    }

    .orderDisplay__container h1{
        font-size: 2rem;
        word-wrap: break-word;
    }
    
    .orderDisplay__container h2{
        font-size: 1rem;
    }
  }

  @media screen and (max-width: 550px) {
    .orderDisplay__container {
      padding: 0 20px; /* Add padding to the container to avoid overflow */
    }

    .orderDisplay__leftSection,
    .orderDisplay__rightSection {
      width: 100%; /* Set width to 100% to avoid overflow */
    }

    .orderDisplay__productLists,
    .orderDisplay__summary {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap; /* Allow items to wrap to the next line */
    }
  }