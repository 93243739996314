.smileyCards__background {
  background-color: #d1b9f0;
  overflow: hidden;
}

.smileyCards__container {
  width: 100%;
}
.smileyCards__heading {
  text-align: center;
  font-size: 30px;
  margin-bottom: 10px;
  margin-top: 60px;
}

.smileyCards__row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-flow: wrap;
}

.smileyCards__card {
  width: 30%; /* Adjust the width to make the cards wider */
  height: auto; /* Let the height adjust automatically based on content */
  margin: 50px auto;
  transition: 0.3s;
  border-radius: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 10px;
}

.smileyCards__cardHeader {
  padding: 20px;
  color: #fff;
  border-radius: 20px 20px 0 0;
  font-size: 7rem;
}

.smileyCards__cardBody {
  font-size: 1rem;
  width: 70%;
  margin-top: -24px;
}

@media screen and (max-width: 1200px) {
    .smileyCards__cardHeader {
        font-size: 5.5rem;
      }

      .smileyCards__cardBody {
        margin-top: 0px;
      }
  }

@media screen and (max-width: 1000px) {
  .smileyCards__card {
    width: 40%;
  }
}

@media screen and (max-width: 620px) {
  .smileyCards__container {
    width: 100%;
  }

  .smileyCards__heading {
    padding: 20px;
    font-size: 20px;
  }

  .smileyCards__card {
    width: 80%;
  }

  .smileyCards__cardHeader {
    font-size: 4.5rem;
  }
}
