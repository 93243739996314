/* Reset */
*{
    overflow-x: hidden;
}

body{
    overflow: hidden;
}

body,p,a,ul,li,h1,h2,h3,h4,h5,h6{
    margin: 0;
    padding: 0;
    text-decoration: none;
}
li{
    list-style: none;
}
/*Fonts*/
* {
    font-family: 'Manrope', sans-serif;
}

.productDisplay__backgroundDiv {
    background-color: #aa98ef;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    overflow-x: hidden;
    padding: 60px 0px;
    box-sizing: border-box;
  }
  
  .ProductCards__card {
    max-width: 500px;
    min-width: 150px;
    margin: 30px;
    overflow-x: hidden;
    background-color: white;
    border-radius: 10px;
    padding: 13px;
    filter: drop-shadow(13px 13px 0px #42497c);
    text-align: center; 
    box-sizing: border-box;

}

.ProductCards__icon {
    color: #42497c;
    font-size: 5.5rem;
    margin: 15px 5px 10px 5px;
}

.ProductCards__topCard{
    display: flex;
    align-items: center;
    justify-content: center;
}

.ProductCards__productsInfo{
    padding-left: 10px;
}

.ProductCards__productsInfo h2{
    font-weight: 800;
    color: #42497c;
    font-size: 1.8rem;
}

.ProductCards__productsInfo h3{
    font-size: 1rem;
    color: #aa98ef;
    font-weight: 500;
    text-align: left;
}

.ProductCards__productsInfo .ProductCards__time{
    font-size: 1rem;
    padding: 0px 0px 5px 0px;
}

.ProductCards__card li{
    color: #42497c;
}

.ProductCards__card p{
    color: #42497c;
    font-weight: 500px;
}

.ProductCards__card hr{
    border: solid #42497c .2px;
    margin: 10px 0px;
}

.ProductCards__card button{
    border: none;
    background-color: #42497c;
    border-radius: 10px;
    padding: 20px 35%;
    color: white;
    font-weight: 800;
    font-size: 1rem;
    cursor: pointer;
    margin: 10px 0px;
}

.ProductCards__card button:hover{
    background-color: #aa98ef;
}


@media (max-width: 410px){
    .ProductCards__icon{
        display: none;
    }

    .ProductCards__topCard{
        justify-content: center;
    }

    .ProductCards__productsInfo h2{
        text-align: center;
    }

    .ProductCards__productsInfo h3{
        text-align: center;
    } 

}
