.adminPage__background{
    background-color: #42497c;
    height: 100vh;
}

.adminPage__container{
    display: flex;
    justify-content: center;
}

.adminPage__orders{
    margin-right: 50px;
}

/* Add padding to table cells */
table {
    width: 100%;
    border-collapse: collapse;
  }
  
  table th,
  table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
    color: white;
  }
  
  /* Center text in header cells */
  table th {
    text-align: center;
  }
  
  /* Add spacing between table rows */
  table tbody tr {
    border-bottom: 1px solid #ddd;
  }
  
  /* Hover effect for table rows */
  table tbody tr:hover {
    background-color: lightslategray;
  }
  