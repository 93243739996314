.cartPage__backgroundColour{
    background-color: #42497c;
    height: 100vh;
}

.cartPage__backgroundColour h1{
    padding-left: 20px;
    font-size: 4rem;
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
}


.cartPage__backgroundItemsNone{
    font-size: 1rem;
    font-weight: 100;
    padding-left: 25px;

}

.cartPage__backgroundContainer{
    display: flex;
    margin: 0px 20px;
    justify-content: center;
}

.cartPage__icon{
    font-size: 2.3rem;
    color: white;
    margin: 0 10px;
}

.cartPage__items div{
    display: flex;
    align-items: center;
    margin: 10px 0 20px 0;
}

.cartPage__items div h3{
    font-size: 1.5rem;
    font-weight: 600;
    margin-right: 20px;
}

.cartPage__items div p{
    font-weight: 300;
    font-size: 1.3rem;
    margin-right: 20px;
}

.cartPage__items div span{
    font-size: 0.9em;
    margin-left: 2px;
}

.cartPage__trashIcon{
    color: white;
    font-size: 1.3rem;
    cursor: pointer;
}

.cartPage__total{
    height: 190px;
    width: 300px;
    background-color: #aa98ef;
    border-radius: 15px;
    margin-left: 100px;
    margin-top: 10px;
    text-align: center;
}

.cartPage__total h2{
    font-size: 2.3rem;
    margin-top: 10px;
}

.cartPage__total p{
    font-size: 1.3rem;
}

.cartPage__total button{
    background-color: #d1b9f0;
    border: none;
    border-radius: 5px;
    padding: 10px;
    color: white;
    font-weight: 500;
    font-size: 1rem;
    margin: 10px;
    cursor: pointer;
}

@media screen and (max-width: 790px) {
    .cartPage__backgroundContainer {
      flex-direction: column; /* Switch to a column layout */
      align-items: center; /* Center the items and total divs vertically */
      justify-content: space-between;
    }

    .cartPage__total{
        margin-left: 0;
    }

    .cartPage__icon{
        display: none;
    }
  
    .cartPage__items div h3{
        margin-right: 10px;
    }
    
    .cartPage__items div p{
        margin-right: 10px;
    }

    .cartPage__items {
      flex-wrap: wrap; /* Allow items to wrap to a new line if necessary */
    }
  
    .cartPage__items > div {
      flex-basis: 50%; /* Make each item take 50% of the container width to display two items in a row */
      padding: 5px; /* Add some spacing between the items */
    }
  }