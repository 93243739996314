.userProfile__background{
    background-color: #42497c;
    height: 100vh;
}

.userProfile__container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.userProfile__container h1{
    font-size: 3rem;
}

.userProfile__adminButton button{
    font-size: 1rem;
    color: #42497c;
    font-weight: 800;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    background-color: #aa98ef;
    cursor: pointer;
    margin: 15px 0px;
}

.userProfile__miniContainer{
    display: flex;
    margin: 40px 0px;
}

.userProfile__updateInfo{
    margin-right: 100px;
}

.userProfile__updateInfo input{
    margin-bottom: 14px;
}

.userProfile__updateInfo button, .userProfile__displayOrders button{
    background-color: #d1b9f0;
    font-size: 0.8rem;
    font-weight: 600;
    color: #42497c;
    border-radius: 5px;
    padding: 4px 8px;
    border: none;
    cursor: pointer;
}

.userProfile__ordersList{
    margin: 0px 0px 15px 0px;
}

.userProfile__logoutSection hr{
    width: 700px;
}

.userProfile__logoutSection{
    text-align: center;
}

.userProfile__logoutSection button{
    background-color: #d1b9f0;
    font-size: 1rem;
    font-weight: 600;
    color: #42497c;
    border-radius: 5px;
    padding: 8px 15px;
    border: none;
    cursor: pointer;
    margin: 20px 0px 0px 0px;
}

@media screen and (max-width: 730px){
    .userProfile__logoutSection hr{
        width: 400px;
    }
}

@media screen and (max-width: 550px){
    .userProfile__logoutSection hr{
        display: none;
    }

    .userProfile__miniContainer{
        flex-direction: column;
        margin: 40px 0px;
    }

    .userProfile__updateInfo{
        margin-bottom: 40px;
    }

}