/* Base styles -- Navbar*/


.Header__fullsizeScreen {
    height: 100vh;
}


.Navbar__container{
    display: flex;
    padding: 30px 20px 40px 20px;
    justify-content: space-between;
}

.Navbar__links{
    display: flex;
    gap: 2rem;
    font-size: 1.2rem;
    font-weight: 500;
}

.Navbar__toggleBtn{
    color: var(--primary);
    font-size: 1.5rem;
    cursor: pointer;
    display: none;
}

.Navbar__container h3 {
    font-weight: 800;
}

.Navbar__socialIcons{
    color: white;
    margin: 0 20px 0 0;
    display: flex;
}

.Navbar__icon {
    margin: 0px 5px;
    display: flex;
    align-items: center;
}

.Navbar__icon .icon {
    margin-right: 3px;
}

.Navbar__cartItemCount{
    color: white;
    padding: 2px 6px;
    font-size: 10px;
    border-radius: 100px;
    background-color: #aa98ef;
    margin-left: -10px;
    margin-right: 5px;
}

.icon {
    padding: 0px 8px;
    font-size: 1.3rem;
    cursor: pointer;
}

.Navbar__dropdownMenu{
    display: none;
    position: absolute;
    right: 2rem;
    top: 60px;
    height: 0;
    width: 300px;
    overflow: hidden;
    border-radius: 10px;
    background: #aa98ef;
    transition: height .2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    z-index: 1;
}

.Navbar__dropdownMenu.open{
    height: 230px;
}

.Navbar__dropdownMenu li{
    padding: 0.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Navbar__dropdownMenu > li:first-child {
    margin-top: 1rem;
}

.Navbar__dropdownMenu .icon{
    color: var(--primary);
    font-size: 1.6rem;
    padding: 0px 20px;
    padding-top: 5px;
}

.Navbar__dropDownLinkDiv {
    display: flex;
    justify-content: center;
}

/*Responsive Navbar*/
@media(max-width: 992px){
    .Navbar__links,
    .Navbar__socialIcons {
        display: none;
    }

    .Navbar__toggleBtn{
        display: block;
    }

    .Navbar__dropdownMenu{
        display: block;
    }
}

@media (max-width: 576px) {
    .Navbar__dropdownMenu {
        left: 2rem;
        width: unset;
    }
}

/*Header nav*/

.Header__imgDiv{
    overflow: hidden;
}

.Header__horoscopeImg {
    background-image: url('../../../public/img/astrolog-horoscope.png');
    height: 550px;
    width: 550px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    margin: auto;
    padding-right:20px;
    animation: rotation 20s infinite linear;
    overflow: hidden;
}

.Header__sunImg {
    background-image: url('../../../public/img/sun.png');
    height: 230px;
    width: 230px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    top: 160px;
    left: 170px;
    animation: none;
    overflow: hidden;
}

.Header__column {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    grid: 20px;
    margin: 40px 0px 0px 0px;
    padding: 0px 30px;
    overflow-x: hidden;
    overflow-y: hidden;
}

.Header_headerText {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px 30px 30px;
    text-align: center;
    overflow: hidden;
}

.Header_headerText p{
    margin: 0px 80px;
}

.Header_headerText  h1 {
    font-size: 6rem;
    font-weight: 800;
    overflow: hidden;
}

.Header__fullsizeScreen{
    background-color:#42497c;
    height: 900px;
    width: 100vw;
    overflow-x: hidden;
    overflow-y: hidden;
}

.Header__svgImage{
    width: 100vw;
    rotate: 180deg;
    height: 250px;
    position: relative;
    top: -0px;
}

/*Animation*/

@keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

/*Media queries*/

@media screen and (min-width: 1250px) {
    .Header__horoscopeImg{
        margin-right: 30px;
    }

    .Header_headerText h1{
        line-height: 6rem;
        padding-bottom: 20px;
    }

}

@media screen and (max-width: 610px){
    .Header__horoscopeImg{
        display: none;
    }

    .Header__sunImg{
        display: none;
    }

    .Header__column{
        padding: 0px;
        margin: 0px;
        display: relative;
        display: grid;
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }

    .Header__column p{
        margin: 0px;
        font-size: 1rem;
    }

    .Header__column h1 {
        font-size: 3.3rem;
    }

    .Header_headerText {
        grid-column: 1/7;
    }

    .Navbar__container{
        padding: 10px 15px;
    }

    .Header__fullsizeScreen{
        height: 40%;
    }

    .Header__svgImage{
        top: 20px;
    }
}
 @media screen and (min-width: 700px) {
    .Header__fullsizeScreen{
        height: 70%;
    }

    .Header__svgImage{
        top: 20px;
    }

 }

 @media screen and (min-width: 1300px) {
    .Header__column{
        margin: 30px 100px;
    }

    .Header__svgImage{
        top: 10px;
    }

    .Header_headerText h1{
        line-height: 5rem;
        padding-bottom: 20px;
    }

    .Header_headerText p{
        margin-top: 20px;
    }
 }
