.paymentScreen__background{
    background-color: #42497c;
}

.paymentScreen__form{
    display: flex;
    align-items: center;
    justify-content: left;
    flex-direction:column ;
    height: 100vh;
}

.paymentScreen__form h1{
    margin-top: 15px;
}

.paymentScreen__form div{
    margin-top: 5px;
}

.paymentScreen__form label{
    color: white;
}

.paymentScreen__form input{
    margin: 0;
    margin-right: 5px;
}

.paymentScreen__form button{
    background-color: #d1b9f0;
    font-size: 1rem;
    font-weight: 600;
    color: #42497c;
    border-radius: 5px;
    padding: 8px 15px;
    border: none;
    cursor: pointer;
    margin: 10px 0px;
}

.paymentScreen__form button:hover{
    opacity: 0.9;
}