.placeOrderScreen__background{
    background-color: #42497c;
    height: 100vh;
}

.placeOrderScreen__container{
    display: flex;
    justify-content: center;
    margin-top: 40px;
}

.placeOrderScreen__container h2{
    font-size: 2rem;
    margin-bottom: 5px;
    font-weight: 800;
}

.placeOrderScreen__leftContainer{
    margin: 0px 40px 0px 0px;
}

.placeOrderScreen__leftContainer hr{
    margin: 20px 0px;
}

.placeOrderScreen__cartItems hr{
    margin: 5px 0px;
}

.placeOrderScreen__cartItem{
    display: flex;
    justify-content: space-between;
    margin:0px 10px 0px 0px ;
}

.placeOrderScreen__rightContainer{
    margin: 0px 0px 0px 100px;
    display: flex;
    align-items: start;
    display: flex;
    align-items: start;
}

.placeOrderScreen__rightContainer button{
    background-color: #d1b9f0;
    font-size: 1rem;
    font-weight: 600;
    color: #42497c;
    border-radius: 5px;
    padding: 8px 15px;
    border: none;
    cursor: pointer;
    margin: 10px 0px;
}

@media screen and (max-width: 700px) {
    .placeOrderScreen__container {
      flex-direction: column;
      align-items: center;
    }
  
    .placeOrderScreen__leftContainer {
      margin: 0px 0px 50px 0px; 
    }
  
    .placeOrderScreen__rightContainer {
      margin: 0px; 
    }
  }

  @media screen and (max-width: 400px) {
    .placeOrderScreen__container {
      flex-direction: column;
      align-items: center;
    }
  
    .placeOrderScreen__leftContainer {
      margin: 0px 15px 50px ; 
    }
  
    .placeOrderScreen__rightContainer {
      margin: 0px 15px; 
    }
  }