.loginForm__background {
    background-color: #42497c;
    height: 100vh;
}

.loginForm__formSection{
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.loginForm__form h1{
    font-size: 2.5rem;
    margin-bottom: 15px;
}

.loginForm__form p{
    margin-bottom: 5px;
    font-weight: 500;
}

.loginForm__form input{
    width: 500px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #d1b9f0;
    height: 25px;
}

.loginForm__form input:active{
    border: none;
}

.loginForm__form button{
    background-color: #d1b9f0;
    font-size: 1rem;
    font-weight: 600;
    color: #42497c;
    border-radius: 5px;
    padding: 8px 15px;
    border: none;
    cursor: pointer;
    margin:10px 0px;
}

.loginForm__form button:hover{
    opacity: 0.9;
}

.loginForm__form span{
    color: #d1b9f0;
    cursor: pointer;
    font-weight: 500;
    text-decoration: underline;
}

@media only screen and (max-width:570px ){
    .loginForm__form input{
        width: 300px;
    }
}

@media only screen and (max-width:370px ){
    .loginForm__form input{
        width: 250px;
    }
}