.Footer_container{
    background-color: #42497c;
}

.Footer_socialIcons {
    color: white;
    font-size: 2.5rem;
}

.Footer__infoContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px ;
}

.footer__icon{
    padding: 0px 20px;
}

.Footer_moreInfo{
    text-align: center;
    margin-top: 10px;
}