@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

/* Variables */
:root{
    --primary: #ffffff;
    --secondary: #aa98ef;
    --backgroundColor: #7360C9;
    --darkColor: #372772;
}

/* Reset */
body,p,a,ul,li,h1,h2,h3,h4,h5,h6{
    margin: 0;
    padding: 0;
    text-decoration: none;
}
li{
    list-style: none;
}
/*Fonts*/
* {
    font-family: 'Manrope', sans-serif;
}

h1,h2,h3,h4,h5,h6,li,a,p{
    color: var(--primary);
}

/*Index page styles*/

.indexPage{
    overflow-x: hidden;
}

/* Cards */

.Cards {
    background-color: #aa98ef;
    padding: 0px 0px 10px 0px;
}

.Cards h2 {
    font-size: 3.5rem;
    text-align: center;
    font-weight: 700;
    padding: 30px 30px 10px 30px;
}

.Cards p {
    text-align: center;
    padding: 10px 20% 20px 20%;
}

.Cards__cardItem {
    border-radius: 10px;
    flex: 1 1 auto;
    min-width: 300px;
    max-width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.Cards__cardItemContainer {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 10%;
    gap: 25px;
    justify-content: center ;
}

.Cards img {
    width:100%;
    position: relative;
    top: 15px;
}

.Cards__icons {
    color: white;
    font-size: 8rem;
    padding-right: 10px;
}

.Cards__cardItem p{
    padding: 0px;
    font-weight: 400;
    font-size: 1.2rem;
}

.Cards__span{
    font-weight: 800;
}

/*Reviews*/

.Reviews__container{
    background-color: #c3a4ec;
}

.CarouselSlider__imgContainer {
    margin: 0px 20px;
    max-width: 500px;
    min-width: 300px;
    flex: 1 1 auto;
  }

.Reviews__container h2{
    font-size: 3.5rem;
    text-align: center;
    font-weight: 700;
    padding: 0px 30px 50px 30px;
}

.CarouselSlider__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 70px;
  }

.Carousel__slider {
    font-size: 3rem;
    color: white;
    background-color: transparent;
    border: none;
    cursor: pointer;
    flex: 1;
  }

.CarouselSlider__img {
    max-width: 100%;
    max-height: 600px;
    min-height: 400px;
    object-fit: contain;
  }

.smileyCards__backgroundWave{
    width:100%;
    position: relative;
    top: 15px;
}

/*Front page*/
.Front__page{
    overflow-x: hidden;
}

/*Media Queries*/

@media screen and (max-width: 610px) {
    .Cards h2{
        font-size: 2.2rem;
    }

    .Cards p {
        padding: 10px 15% 20px 15%;
    }

    .Cards__cardItem {
        flex-direction: column;
    }

    .Cards__icons {
        font-size: 2.5rem;
    }

    .Reviews__ulDisplay{
        flex-direction: row;
    }    

    .Reviews__ulDisplay li{
        height: auto;
        width: 100%;
    }

    .Reviews__ulDisplay img{
        width: 100%;
        max-height: 75vh;
        min-width: 0;
    }

    .Reviews__reviewMobileRemove{
        display: none;
    }
}

