
.about_container{
    background-color: #aa98ef;
    display: flex;
    padding: 30px;
    align-items: center;
}

.about_img img{
    width: 100%;
}

.about_text{
    margin-left: 30px;
}

.about_text h4{
    font-size: 2rem;
}

.about_text p{
    margin: 10px 0px;
}

@media(max-width: 700px){
    .about_container{
        flex-direction: column;
        text-align: center;
    }

    .about_text{
        margin-left: 0px;
    }
}